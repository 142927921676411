body {
  font-family: "Inter", sans-serif;
}

.serp-comparison {
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.logo {
  width: 40px;
  height: auto;
  margin-right: 10px;
}

h1 {
  margin: 0;
}

.domain-select-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.select-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  padding: 5px 10px;
  margin-left: 91px;
}

.domain-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  border: none;
  font-size: 12px;
  padding-right: 20px;
  cursor: pointer;
}

.domain-select:focus {
  outline: none;
}

.input-container {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  max-width: 800px;
  margin: 0 auto;
}

.keyword-input {
  flex: 1;
  padding: 10px 15px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  background-color: #F3F3F3;
}

.vs {
  margin: 0 15px;
  font-weight: bold;
  font-size: 11px;
}

.compare-button {
  margin-left: 15px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #000;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.compare-button:hover {
  background-color: #333333;
}

/* Ensure inputs don't have default outlines */
.serp-comparison .keyword-input:focus,
.serp-comparison .compare-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255,255,255,0.5);
}

.chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
  width: 100vw;
  margin-top: 30px;
  overflow-x: auto;  /* Added to handle potential overflow */
}

.chart-container > div {
  min-width: 1000px;  /* Ensures the chart doesn't shrink below this width */
}

.footer {
  margin-top: 40px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.cogney-logo {
  height: 10px;
  margin-left: 6px;
}

/* Style the plot link elements */
a text {
  cursor: pointer;
  fill: black;
}

/* Hover effect */
a text:hover {
  fill: blue;
  text-decoration: underline;
}